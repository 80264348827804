.dashboard-container {
    width: 100%;
    padding: 70px;
    background: #1E2222;
}

.title__text {
    font-family: 'poppins';
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    color: #E8E8E8;
    margin-bottom: 67px;
}

.section__heading {
    text-align: center;
}

span.seaction__heading-text {
    font-family: 'poppins';
    color: #E8E8E8;
    font-style: normal;
    font-weight: 600;
    font-size: 26.6667px;
    line-height: 40px;
}

.container-top-section {
    justify-content: space-between;
    display: flex;
    color: white;
}

.card-container-top-section__heading {
    font-family: 'poppins';
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: rgba(232, 232, 232, 1);
}

.card-container-top-section__value{
    font-family: 'poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */
    letter-spacing: -0.05em;

    background: radial-gradient(50.49% 183.68% at 100% 50%, #26AE55 0%, #20EB66 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}