.card-container {
    /* min-width: 25%; */
    width: 30%;
    min-height: 263px;
    background: #1B1E1F;
    border-radius: 25px;
    padding:23px;
    margin-right: 8px;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
}