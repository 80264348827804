.main-container{
    display: flex;
}

img.menu-icon-img {
    width: 40px;
    margin-left: 8px;
}

.mobile-navbar-toggler{
    display: none;
}

.navbar-container__main{
    display: block;       
    transition: 8s;
}

@media screen and (max-width:760px) {
    .mobile-navbar-toggler{
        display: block;
        cursor: pointer;
    }   

    .navbar-container__main{
        display: none;       
    }

    .navbar-container__main.navbar-container-active{
        display: block;
        
    }

}